import { render, staticRenderFns } from "./DragControlBar.vue?vue&type=template&id=0615e963&scoped=true&"
import script from "./DragControlBar.vue?vue&type=script&lang=js&"
export * from "./DragControlBar.vue?vue&type=script&lang=js&"
import style0 from "./DragControlBar.vue?vue&type=style&index=0&id=0615e963&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0615e963",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VBtnToggle,VIcon,VToolbar,VTooltip})
