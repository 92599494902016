<template>
  <v-dialog v-model="active">
    <v-card>
      <v-toolbar class="df-toolbar" height="70">
        <v-menu
          offset-y
          nudge-top="10"
          min-width="230"
          nudge-left="15"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, value }">
            <div class="df-select d-flex flex-row align-center" v-on="on">
              <v-icon left v-if="value">mdi-menu-up</v-icon>
              <v-icon left v-else>mdi-menu-down</v-icon>
              <div>
                <div
                  class="caption greytext--text font-weight-bold text-uppercase"
                >
                  {{ $t("charts.chart_type") }}
                </div>
                <p class="text-small pa-0 ma-0">
                  {{ $t("charts.select_chart_type") }}
                </p>

                <div class="body-2 font-weight-bold pr-12 text-capitalize">
                  {{ activeChartType }}
                </div>
              </div>
            </div>
          </template>

          <v-list>
            <v-list-item
              dense
              v-for="(item, index) in chartTypes"
              :key="index"
              @click="onChartTypeChanged(item)"
            >
              <v-list-item-title class="text-capitalize">
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical class="mx-3"></v-divider>

        <v-menu
          offset-y
          nudge-top="10"
          min-width="230"
          nudge-left="42"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, value }">
            <div class="df-select d-flex flex-row align-center" v-on="on">
              <v-icon left v-if="value">mdi-menu-up</v-icon>
              <v-icon left v-else>mdi-menu-down</v-icon>
              <div>
                <div
                  class="caption greytext--text font-weight-bold text-uppercase"
                >
                  {{ $t("charts.x_axis") }}
                </div>
                <p class="text-small pa-0 ma-0">
                  {{ $t("charts.x_axis_description") }}
                </p>

                <div class="body-2 font-weight-bold pr-12">
                  {{ activeX || $t("charts.show_all") }}
                </div>
              </div>
            </div>
          </template>

          <v-list>
            <v-list-item
              dense
              v-for="(item, index) in xAxis"
              :key="index"
              @click="onXAxisChanged(item)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical class="mx-3"></v-divider>

        <v-menu
          offset-y
          nudge-top="10"
          min-width="230"
          nudge-left="68"
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on, value }">
            <div class="df-select d-flex flex-row align-center" v-on="on">
              <v-icon left v-if="value">mdi-menu-up</v-icon>
              <v-icon left v-else>mdi-menu-down</v-icon>
              <div>
                <div
                  class="caption greytext--text font-weight-bold text-uppercase"
                >
                  {{ $t("charts.y_axis") }}
                </div>
                <p class="text-small pa-0 ma-0">
                  {{ $t("charts.y_axis_description") }}
                </p>

                <div class="body-2 font-weight-bold pr-12">
                  {{ activeY || $t("charts.show_all") }}
                </div>
              </div>
            </div>
          </template>

          <v-list>
            <v-list-item
              dense
              v-for="(item, index) in yAxis"
              :key="index"
              @click="onYAxisChanged(item)"
            >
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-divider vertical class="mx-3"></v-divider>
      </v-toolbar>

      <v-container fill-height fluid pa-0>
        <v-row>
          <v-col class="pa-12">
            <vue-apex-charts
              height="600px"
              ref="dfChart"
              :type="activeChartType"
              :options="chartOptions"
              :series="series"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import EventBus from "../../event-bus";

const demoXAxis = ["< 10", ">= 10", ">= 20", ">= 30"];
const demoYAxis = [1995, 1999, 2010, 2019];
const demoSeries = {
  car: [20, 40, 80, 200],
  container: [30, 90, 100, 405],
  cargo: [50, 80, 120, 210],
};
const seriesData = [
  {
    name: "car",
    data: demoSeries["car"],
  },
  {
    name: "container",
    data: demoSeries["container"],
  },
  {
    name: "cargo",
    data: demoSeries["cargo"],
  },
];

const initialOptions = {
  chart: {
    id: "df-detect-monitor-chart",
  },
  xaxis: {
    categories: demoYAxis,
  },
  markers: {
    size: 5,
  },
};

export default {
  components: {
    VueApexCharts,
  },
  data() {
    return {
      active: false,
      chartTypes: ["bar", "line", "radar", "area"],
      activeChartType: "bar",
      xAxis: ["", ...demoXAxis],
      yAxis: ["", ...demoYAxis],
      activeX: "",
      activeY: "",
      series: [...seriesData],
      chartOptions: { ...initialOptions },
    };
  },
  mounted() {
    EventBus.$off("chart:toggle");

    EventBus.$on("chart:toggle", (visible) => {
      if (visible !== undefined) {
        this.active = visible;
      } else {
        this.active = !this.active;
      }
    });
  },
  methods: {
    onChartTypeChanged(item) {
      this.activeChartType = item;
    },
    onXAxisChanged(item) {
      this.activeX = item;
    },
    onYAxisChanged(item) {
      this.activeY = item;
    },
  },
  watch: {
    active(newVal) {
      if (!newVal) {
        EventBus.$emit("chart:off");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.df-toolbar {
  box-shadow: 0px 1px 5px rgba(48, 48, 48, 0.1);
}
.df-select {
  cursor: pointer;
  width: 230px;
  zoom: 0.9;
}
.caption {
  font-size: 12px !important;
}
.no-indent {
  text-indent: 0;
  letter-spacing: 0;
}
.segments {
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  height: 44px;
  cursor: pointer;
  overflow: hidden;
  font-size: 14px;
}
.text-small {
  font-size: 12px;
  color: #666;
}
</style>
