import * as olExtent from "ol/extent";

import { Fill, Stroke, Style } from "ol/style";
import { Modify, Snap } from "ol/interaction";

import Constants from "../config/constants";
import { Feature } from "ol";
import GeoJSON from "ol/format/GeoJSON";
import { Polygon } from "ol/geom";
import Transform from "ol-ext/interaction/Transform";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { toLonLat } from "ol/proj";

export const setTransformStyles = () => {};

export default class DetectMonitorController {
  constructor(map, eventBus) {
    this._map = map;
    this._rasters = [];

    this._rasters = this._map
      .getLayers()
      .getArray()
      .filter(layer => {
        const predefinedLayers = ["map-center-layer", "map-center-layer-2019"];
        return predefinedLayers.some(item => layer.get("name") === item);
      });

    this._customArea = undefined;
    this._eventBus = eventBus;
    this._dragCustomInteractions = {
      snap: undefined,
      modify: undefined,
      transform: undefined
    };
    this._centerFeature = undefined;
  }

  setupDragCustom() {
    this._customArea = new VectorLayer({
      name: Constants.CUSTOM_AREA_LAYER_NAME,
      source: new VectorSource({ wrapX: false }),
      style: new Style({
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        stroke: new Stroke({ color: [255, 82, 82, 0.8], width: 3 }),
        fill: new Fill({ color: [0, 0, 0, 0] })
      })
    });

    this._dragCustomInteractions.snap = new Snap({
      source: this._customArea.getSource()
    });

    this._dragCustomInteractions.modify = new Modify({
      source: this._customArea.getSource(),
      hitTolerance: 10,
      active: false
    });

    this._dragCustomInteractions.transform = new Transform({
      source: this._customArea.getSource(),
      translateFeature: true,
      hitTolerance: 10,
      active: true
    });

    this._map.addInteraction(this._dragCustomInteractions.snap);
    this._map.addInteraction(this._dragCustomInteractions.modify);
    this._map.addInteraction(this._dragCustomInteractions.transform);

    this.disableAllInteractions();

    this._map.addLayer(this._customArea);

    document.addEventListener("keydown", event => {
      switch (event.keyCode) {
        case 27: //ESC
          this._onEscKey();
          break;
        case 18: //LeftAlt
          this.onLeftAltKey();
          break;
        default:
          break;
      }
    });
  }

  _initDragCustomRectangle() {
    const center = this._map.getView().getCenter();
    const width = 540;
    const height = 420;

    this._centerFeature = new Feature(
      new Polygon([
        [
          [center[0] - width, center[1] - height],
          [center[0] + width, center[1] - height],
          [center[0] + width, center[1] + height],
          [center[0] - width, center[1] + height],
          [center[0] - width, center[1] - height]
        ]
      ])
    );
    this._centerFeature.setId(Constants.CENTER_FEATURE_NAME);
    this._map.on("click", e => {
      this._map.forEachFeatureAtPixel(e.pixel, (feature, layer) => {
        if (feature.getId() === Constants.CENTER_FEATURE_NAME) {
          this._eventBus.$emit("DetectMonitor:onCenterFeatureClick");
        }
      });
    });
  }

  applyDragArea(centerRoiAsGeoJSON, useProjection = false) {
    this._initDragCustomRectangle();
    this._customArea.setSource(
      new VectorSource({
        features: new GeoJSON(
          useProjection
            ? {
                dataProjection: Constants.DEFAULT_DATA_PROJECTION,
                featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
              }
            : {}
        ).readFeatures(centerRoiAsGeoJSON)
      })
    );
    this._centerFeature = this._customArea
      .getSource()
      .getFeatureById("center-feature");
    this._showDragArea();
  }

  resetDragCustomShape() {
    this._hideDragArea();
    this._initDragCustomRectangle();
    this._showDragArea();
  }

  clearCustomArea() {
    this._customArea.getSource().clear();
    this._customArea.setVisible(false);
  }

  getDragCustomArea() {
    const roi = this._customArea.getSource().getFeatures();

    const writer = new GeoJSON({
      dataProjection: Constants.DEFAULT_DATA_PROJECTION,
      featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
    });

    const geoData = writer.writeFeaturesObject(roi);

    return {
      center: toLonLat(olExtent.getCenter(roi[0].getGeometry().getExtent())),
      roiObject: geoData,
      roi: toLonLat(roi[0])
    };
  }

  _hideDragArea() {
    this._customArea.setVisible(false);
    this._customArea.getSource().clear();
    this.disableAllInteractions();
  }

  _showDragArea() {
    this._customArea.getSource().clear();
    this._customArea.getSource().addFeature(this._centerFeature);
    this._customArea.setVisible(true);
  }

  toggleDragArea(enabled) {
    if (!enabled) {
      this._selectCenterFeatureForTransform(false);
      this._hideDragArea();
    } else {
      if (!this._centerFeature) {
        this._initDragCustomRectangle();
      }

      this._showDragArea();

      this._selectCenterFeatureForTransform(true);
      this.enableDragCustomTransform();
    }
  }

  _translateFeatureToMapCenter() {
    const featureCenter = olExtent.getCenter(
      this._centerFeature.getGeometry().getExtent()
    );
    const mapCenter = this._map.getView().getCenter();
    this._centerFeature
      .getGeometry()
      .translate(
        mapCenter[0] - featureCenter[0],
        mapCenter[1] - featureCenter[1]
      );
  }

  _selectCenterFeatureForTransform(enabledSelect) {
    this._dragCustomInteractions.transform.select(
      this._customArea.getSource().getFeatures()[0],
      enabledSelect
    );
  }

  enableDragCustomModify() {
    this._dragCustomInteractions.modify.setActive(true);
    this._dragCustomInteractions.transform.setActive(false);
  }

  enableDragCustomTransform() {
    this._dragCustomInteractions.modify.setActive(false);
    this._dragCustomInteractions.transform.setActive(true);

    this._selectCenterFeatureForTransform(true);
  }

  disableAllInteractions() {
    this._dragCustomInteractions.modify.setActive(false);
    this._dragCustomInteractions.transform.setActive(false);
  }

  __trackCenterRoi(ctx, pixelRatio) {
    const center = ctx._map.getPixelFromCoordinate(
      ctx._map.getView().getCenter()
    );

    const radius = ctx._centerDiv.clientWidth / 2;
    const toCoordinates = viewportPoint =>
      toLonLat(ctx._map.getCoordinateFromPixel(viewportPoint));

    const vertices = [
      toCoordinates([
        (center[0] - radius) * pixelRatio,
        (center[1] - radius) * pixelRatio
      ]),
      toCoordinates([
        (center[0] + radius) * pixelRatio,
        (center[1] - radius) * pixelRatio
      ]),
      toCoordinates([
        (center[0] + radius) * pixelRatio,
        (center[1] + radius) * pixelRatio
      ]),
      toCoordinates([
        (center[0] - radius) * pixelRatio,
        (center[1] + radius) * pixelRatio
      ])
    ];

    ctx._map.set(Constants.MAP_CENTER_ROI_PROP, {
      bbox: [...vertices, vertices[0]],
      origin: toCoordinates(center)
    });
  }

  setupCenterRoi(centerDiv) {
    this._centerDiv = centerDiv;
    const listener = event =>
      this.__trackCenterRoi(this, event.frameState.pixelRatio);

    this._rasters.forEach(layer => {
      layer.un("prerender", listener);
      layer.on("prerender", listener);
    });
  }
  getFeatureLayer() {
    const layer = this._map
      .getLayers()
      .getArray()
      .find(item => item.get("name") === Constants.FEATURE_LAYER_NAME);

    if (!layer) {
      throw new Error("Feature layer does not exist");
    }
    return layer;
  }
  setFeaturesLayerVisible(enabled) {
    const layer = this.getFeatureLayer();
    layer.setVisible(enabled);
  }

  _onEscKey() {
    //pass
  }

  _onLeftAltKey() {
    //pass
  }
}
