<template>
  <div class="object-type-filter d-flex">
    <v-container fluid pa-0>
      <v-card depressed elevation="0">
        <v-card-title primary-title>
          {{ $t("object_filter_block.title") }}
          <span class="filter-label">
            {{ $t("object_filter_block.filter") }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-0">
          <perfect-scrollbar>
            <v-list one-line dense class="object-types-list">
              <v-list-item-group
                multiple
                :value="selected"
                v-for="category in categories"
                :key="category.id"
                color="secondary"
              >
                <v-list-item
                  v-show="checkAvailable(childCategory)"
                  v-for="childCategory in category.children"
                  :key="childCategory.id"
                  @click="selectedItem = childCategory.id"
                  :value="childCategory.id"
                >
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ childCategory.name }}
                        <div class="marker">
                          <img
                            :src="getMarkerSrc(childCategory)"
                            :alt="childCategory.name"
                          />
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <div @click="toggle" v-show="active" class="check-icon">
                        <v-icon right>mdi-check</v-icon>
                      </div>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </perfect-scrollbar>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    items: Array,
    selected: Array,
    filters: Array
  },
  methods: {
    checkAvailable(childCategory) {
      return this.filters.findIndex(item => item === childCategory.id) >= 0;
    },
    getMarkerSrc(childCategory) {
      const re = new RegExp("^(http|https)://", "i");
      const marker_src = re.test(childCategory.marker)
        ? childCategory.marker
        : process.env.VUE_APP_API_BASE_URL + childCategory.marker;

      return marker_src;
    }
  },
  computed: {
    categories() {
      return this.items;
    },
    selectedItem: {
      get() {
        return this.selected;
      },
      set(value) {
        return this.$emit("value", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.object-type-filter {
  position: absolute;
  z-index: 99;
  top: calc(191px + 20px);
  left: 10px;
  width: 342px;
  height: 300px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.85);
  transition: all 0.2s ease-in-out;
  &:hover {
    background: rgba(255, 255, 255, 1);
  }
  .filter-label {
    position: absolute;
    font-size: 12px;
    font-weight: normal;
    right: 20px;
    top: 18px;
  }
  .v-card {
    border-radius: 0;
    background: transparent;
  }
  .vcard__text {
    background: transparent;
  }
  .v-list {
    background: transparent;
    height: 230px;
  }
  .v-list-item {
    position: relative;
  }
  .v-item--active {
    background: transparent;
  }
  .marker {
    opacity: 0.7;
    display: inline;
    position: absolute;
    left: 150px;
    top: 10px;
    img {
      width: 85%;
    }
  }
  .check-icon {
    position: absolute;
    right: 20px;
    top: 8px;
  }
}
</style>
