<template>
  <div class="slider-block">
    <v-row class="no-gutters">
      <v-slider
        class="custom-slider round-tick-slider small-label-slider"
        :tick-labels="years"
        min="0"
        max="3"
        v-model="active"
        ticks="always"
        tick-size="6"
        @input="sliderInput"
      ></v-slider>

      <v-btn class="button" icon rounded @click="onExtendButtonClicked">
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import bus from "../../event-bus";

export default {
  props: {
    value: { type: String },
  },
  data() {
    // TODO: NhanPM This is dummy data, will be replaced with api result.
    return {
      years: ["1995", "1999", "2010", "2019"], //
      active: "",
    };
  },
  watch: {
    "$store.state.session.selectedYear": {
      handler(newVal) {
        this.active = this.years.findIndex((item) => item === newVal);
      },
    },
  },
  methods: {
    sliderInput(val) {
      this.$store.dispatch("session/updateSelectedYear", this.years[val]);
      this.$store.dispatch("session/updateDetected", false);
    },
    onExtendButtonClicked() {
      //trang.tn commented to remove disc login
      // if (this.$store.getters["user/isLoggedIn"]) {
      //   bus.$emit("chart:toggle");
      // } else {
      //   this.$store.dispatch(
      //     "toggleLoginDialog",
      //     { form: "login" },
      //     { root: true }
      //   );
      // }
      bus.$emit("chart:toggle");
    },
  },
  mounted() {
    this.$store.dispatch("session/updateSelectedYear", this.years[0]);
  },
};
</script>

<style lang="scss" scoped>
.slider-block {
  border-radius: 0px;
  padding: 10px 12px 0 12px;
  background: white;
  .slider {
    padding: 12px;
  }
  .button {
    // width: 10px;
    background: transparent;
  }
  .custom-slider {
    padding: 0 10px;
    position: relative;
    top: 3px;
  }
}
</style>
