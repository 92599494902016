<template>
  <transition name="slide">
    <div class="drag-control-bar">
      <v-toolbar dense small class="pa-0 content no-padding-content">
        <v-btn-toggle
          :value.sync="value"
          @change="emitChange"
          mandatory
          borderless
          dense
          depressed
          class="custom-padding-button-group top-left-radius bottom-left-radius"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon dense value="transform" v-on="on">
                <v-icon>mdi-pan</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("button_tooltips.transform") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon dense value="modify" v-on="on">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("button_tooltips.modify") }}</span>
          </v-tooltip>
        </v-btn-toggle>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              dense
              value="refocus"
              v-on="on"
              color="primary"
              @click="$emit('onRefocus')"
            >
              <v-icon>mdi-image-filter-center-focus</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button_tooltips.refocus") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dense
              icon
              color="primary darken-1"
              v-on="on"
              @click="$emit('onRefresh')"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button_tooltips.refresh") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dense
              icon
              color="secondary"
              v-on="on"
              @click="$emit('onSave')"
            >
              <v-icon>mdi-check</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button_tooltips.save") }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              dense
              icon
              color="orange darken-1"
              v-on="on"
              @click="$emit('onClose')"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button_tooltips.close") }}</span>
        </v-tooltip>
      </v-toolbar>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    visible: { type: Boolean, default: false },
    value: { type: String, default: "transform" }
  },
  methods: {
    emitChange(action) {
      this.$emit("input", action);
    }
  }
};
</script>

<style lang="scss" scoped>
.drag-control-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  top: 10px;
  z-index: 100;
  pointer-events: none;

  .content {
    border-radius: 5px;
    position: relative;
    pointer-events: all;
    display: flex;
    max-width: 290px;
    .v-toolbar__content {
      padding: 0 !important;
    }
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: margin-top 0.2s ease-out;
}
.slide-enter,
.slide-leave-to {
  margin-top: -50px;
}
.slide-enter-to,
.slide-leave {
  margin-top: 0px;
}
</style>
