<template>
  <div class="annotate">
    <v-container fill-height fluid pa-0 align-start id="inspire">
      <v-row no-gutters class="top-toolbar">
        <v-col>
          <df-toolbar></df-toolbar>
        </v-col>
      </v-row>

      <v-row no-gutters class="content-row">
        <v-col>
          <div class="map-view-container map-container">
            <map-view-detect />

            <df-block-detect
              v-if="
                (!$store.state.session.isDragCustomMode &&
                  $store.state.session.session.address) ||
                  ($store.state.session.isDragCustomMode &&
                    $store.state.session.isFinishDragCustom)
              "
              @onDetect="startDetect"
            />

            <df-object-type-filter
              v-if="$store.state.session.session.detected"
              :items="objectTypes"
              :selected="selectedObjectTypes"
              @value="selectedObjectTypes = $event"
              :filters="$store.state.session.availableObjectTypes"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <df-timeline-toolbar class="timeline-container content-row" />
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <df-chart-view></df-chart-view>
    </v-container>
  </div>
</template>

<script>
import DfToolbar from "@/components/general/DfToolbar.vue";
import MapViewDetect from "@/components/map/MapViewDetect.vue";
import DfBlockDetect from "../components/general/DfBlockDetect.vue";
import DfObjectTypeFilter from "../components/general/DfObjectTypeFilter.vue";
import { getFeatures } from "../../data/DummyObject";
import mapControl from "../lib/map-controller";
import { SessionTypes } from "../store/session";
import EventBus from "../event-bus";
import Constants from "../config/constants";
import DfTimelineToolbar from "../components/general/DfTimelineToolbar";
import DfChartView from "../components/charts/DfChartView";

export default {
  components: {
    DfToolbar,
    MapViewDetect,
    DfBlockDetect,
    DfObjectTypeFilter,
    DfTimelineToolbar,
    DfChartView
  },

  data() {
    return {
      enabledDetect: false,
      moveAreaCenterOnDetect: false,
      // TODO: NhanPM replace with API call later
      selectedYear: Constants.DEFAULT_MAP_ID
    };
  },
  mounted() {
    this.$store.dispatch("session/resetSession");
    this.$store.dispatch("session/setSessionType", SessionTypes.detect);
  },
  methods: {
    startDetect() {
      this.$store.dispatch("session/loadObjectTypes", { useCache: true });
      this.$store.dispatch("session/updateDetected", true);
      this.setFilterObjects();
    },

    setFilterObjects() {
      //TODO: API call and process logic in store
      //we store reactive roi data inside map instance,
      //due to we are using prerender hook to recalculate center roi,
      //we need to define different keys to avoid reseting position every frame
      //when changing between modes
      let roi = this.$store.state.session.isDragCustomMode
        ? this.$map.get("center-roi-custom")
        : this.$map.get("center-roi");

      const { availableObjectTypes, visibleFeatures } = getFeatures(
        roi || { bbox: [] },
        this.selectedYear
      );

      EventBus.$emit("DetectMonitor:detected", {
        ctx: this,
        availableObjectTypes,
        visibleFeatures
      });

      this.$store.dispatch(
        "session/updateAvailableObjectTypes",
        availableObjectTypes
      );

      //default select all available object types
      this.$store.dispatch(
        "session/updateSelectedObjectTypes",
        availableObjectTypes
      );

      if (this.moveAreaCenterOnDetect) {
        const coordinates = {
          long: this.$store.state.session.session.longitude,
          lat: this.$store.state.session.session.latitude
        };
        mapControl.moveToLocation(this.$map, coordinates, true);
      }
    }
  },

  computed: {
    objectTypes() {
      return this.$store.state.session.objectTypes;
    },
    selectedObjectTypes: {
      get() {
        return this.$store.state.session.selectedObjectTypes;
      },
      set(value) {
        this.$store.dispatch("session/updateSingleSelectedObjectType", value);
      }
    }
  },

  watch: {
    "$store.state.session.session.address"(val) {
      this.enabledDetect = val ? true : false;
    },
    "$store.state.session.selectedYear": {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && oldVal !== "") {
          this.selectedYear = newVal;
          if (this.$store.state.session.session.detected) {
            this.setFilterObjects();
          }
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.annotate {
  background: darkolivegreen;
}
.content-row {
  position: relative;
}

.map-view-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.timeline-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 0;
}
</style>
