var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"slide"}},[_c('div',{staticClass:"drag-control-bar"},[_c('v-toolbar',{staticClass:"pa-0 content no-padding-content",attrs:{"dense":"","small":""}},[_c('v-btn-toggle',{staticClass:"custom-padding-button-group top-left-radius bottom-left-radius",attrs:{"value":_vm.value,"mandatory":"","borderless":"","dense":"","depressed":""},on:{"update:value":function($event){_vm.value=$event},"change":_vm.emitChange}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dense":"","value":"transform"}},on),[_c('v-icon',[_vm._v("mdi-pan")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("button_tooltips.transform")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dense":"","value":"modify"}},on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("button_tooltips.modify")))])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","dense":"","value":"refocus","color":"primary"},on:{"click":function($event){return _vm.$emit('onRefocus')}}},on),[_c('v-icon',[_vm._v("mdi-image-filter-center-focus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("button_tooltips.refocus")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","icon":"","color":"primary darken-1"},on:{"click":function($event){return _vm.$emit('onRefresh')}}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("button_tooltips.refresh")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","icon":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('onSave')}}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("button_tooltips.save")))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"dense":"","icon":"","color":"orange darken-1"},on:{"click":function($event){return _vm.$emit('onClose')}}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("button_tooltips.close")))])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }