<template>
  <div class="map-wrapper">
    <div id="map" class="map" :class="{ 'ol-layer-blur': enableBlurMap }"></div>
    <div
      class="center-roi-wrapper"
      v-show="shouldShowCenterRoi && !$store.state.session.isDragCustomMode"
    >
      <div
        class="center-roi"
        :class="{ active: $store.state.session.session.detected }"
        id="center-roi"
        :style="{ height: roiSize, width: roiSize, minWidth: roiSize }"
      ></div>
    </div>

    <drag-control-bar
      v-model="dragControlBarAction"
      v-show="canDetect"
      @onSave="onButtonAction('save')"
      @onRefocus="onButtonAction('refocus')"
      @onClose="onButtonAction('close')"
      @onRefresh="onButtonAction('refresh')"
    />
  </div>
</template>

<script>
import Vue from "vue";
import Map from "ol/Map.js";
import View from "ol/View.js";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import {
  XYZ as XYZSource,
  Vector as VectorSource,
  BingMaps as BingMapsSource
} from "ol/source";
import mapControl from "../../lib/map-controller";
import GeoJSON from "ol/format/GeoJSON";
import { fromLonLat } from "ol/proj";
import { Icon, Style } from "ol/style";
import EventBus from "../../event-bus";
import { getFeatures } from "../../../data/DummyObject";
import DragControlBar from "./DragControlBar";
import DetectMonitorController from "../../lib/detect-monitor-controller";
import Constants from "../../config/constants";
import { DETECT_MONITOR_DEMO_DATA } from "../../../data/DemoData";

export default {
  name: "map-view",

  components: {
    DragControlBar
  },

  props: {
    enableBlurMap: { type: Boolean, default: false },
    allowZoom: { type: Boolean, default: true },
    roiSize: { type: String, default: Constants.DEFAULT_ROI_SIZE }
  },

  data() {
    return {
      dragControlBarAction: "transform"
    };
  },

  mounted() {
    const source = new XYZSource({
      url: `${process.env.VUE_APP_MAPBOX_URL}${process.env.VUE_APP_MAPBOX_ACCESS_TOKEN}`
    });

    const raster = new TileLayer({
      name: Constants.MAP_CENTER_LAYER_NAME,
      source
    });

    const vectorSource = new VectorSource({
      features: new GeoJSON().readFeatures(
        getFeatures({ bbox: [] }, Constants.DEFAULT_MAP_ID).visibleFeatures
      )
    });

    // TODO: Nhanpm: replace map layer with api call later
    let bingLayer = new TileLayer({
      name: "map-center-layer-2019",
      visible: false,
      preload: Infinity,
      source: new BingMapsSource({
        key: process.env.VUE_APP_BING_MAP_KEY,
        imagerySet: "Aerial"
      })
    });

    this.vector = new VectorLayer({
      name: Constants.FEATURE_LAYER_NAME,
      source: vectorSource,
      opacity: 0.75
    });

    this.layers = [raster, bingLayer, this.vector];
    const container = document.getElementById("map");
    const centerDiv = document.getElementById("center-roi");

    this.map = new Map({
      layers: this.layers,
      target: container,
      controls: []
    });

    mapControl.setZoomEnable(this.map, this.allowZoom);

    const viewMap = new View({
      center: fromLonLat([
        DETECT_MONITOR_DEMO_DATA.geometry.coordinates[0],
        DETECT_MONITOR_DEMO_DATA.geometry.coordinates[1]
      ]),
      zoom: process.env.VUE_APP_ZOOM_LEVEL
    });

    this.map.setView(viewMap);

    this.detectMonitorController = new DetectMonitorController(
      this.map,
      EventBus
    );

    this.detectMonitorController.setupDragCustom();
    this.detectMonitorController.setupCenterRoi(centerDiv);

    //assign the map to global vue instance
    this.map.controller = this.detectMonitorController;
    Vue.prototype.$map = this.map;

    EventBus.$off("DetectMonitor:onStartDragCustomMode");
    EventBus.$off("DetectMonitor:onStopDragCustomMode");
    EventBus.$off("DetectMonitor:detected");
    EventBus.$off("DetectMonitor:onCenterFeatureClick");

    EventBus.$on("DetectMonitor:detected", ({ visibleFeatures }) => {
      this.loadFeatures(visibleFeatures);
    });

    EventBus.$on("DetectMonitor:onStartDragCustomMode", () => {
      this.$store.dispatch("session/updateDragCustom", true);
      this.$store.dispatch("session/finishDragCustom", false);
      this.initDragCustom(true);

      const center = fromLonLat([
        DETECT_MONITOR_DEMO_DATA.geometry.coordinates[0],
        DETECT_MONITOR_DEMO_DATA.geometry.coordinates[1]
      ]);

      mapControl.animateTo(
        this.$map,
        center,
        process.env.VUE_APP_ZOOM_LEVEL,
        () => {
          this.onButtonAction("refresh");
        }
      );
    });

    EventBus.$on("DetectMonitor:onStopDragCustomMode", () => {
      this.initDragCustom(false);

      this.detectMonitorController.setFeaturesLayerVisible(false);
      this.detectMonitorController.clearCustomArea();
      this.detectMonitorController.setupCenterRoi(centerDiv);
    });

    EventBus.$on("DetectMonitor:onCenterFeatureClick", () => {
      //pass
    });

    this.map.on("postrender", () => {
      const item = DETECT_MONITOR_DEMO_DATA;
      this.$store.dispatch("session/updateSessionAddress", {
        location_name: item.text,
        address: item.place_name,
        latitude: item.geometry.coordinates[1],
        longitude: item.geometry.coordinates[0],
        detected: false
      });
    });
  },

  computed: {
    canDetect() {
      return (
        this.$store.state.session.isDragCustomMode &&
        !this.$store.state.session.isFinishDragCustom
      );
    },
    objectTypes() {
      return this.$store.state.session.objectTypes;
    },

    selectedObjectTypes: {
      get() {
        return this.$store.state.session.selectedObjectTypes;
      }
    },

    shouldShowCenterRoi() {
      return (
        this.$store.state.session.session.address &&
        this.$store.state.session.session.address.length > 0
      );
    }
  },

  watch: {
    "$store.state.session.selectedYear": {
      handler(val) {
        const roi = this.$store.state.session.isDragCustomMode
          ? this.$map.get(Constants.MAP_CENTER_ROI_CUSTOM_PROP)
          : this.$map.get(Constants.MAP_CENTER_ROI_PROP);

        const { visibleFeatures } = getFeatures(roi || { bbox: [] }, val);

        const vectorSource = new VectorSource({
          features: new GeoJSON().readFeatures(visibleFeatures)
        });

        this.vector.setSource(vectorSource);

        // TODO: (Nhanpm) This is for demonstration, will be replaced with real raster
        if (val !== "2019") {
          this.layers[0].setVisible(true);
          this.layers[1].setVisible(false);
        } else {
          this.layers[0].setVisible(false);
          this.layers[1].setVisible(true);
        }
      }
    },
    "$store.state.session.session": {
      deep: true,
      handler(val) {
        if (
          this.shouldShowCenterRoi &&
          !this.$store.state.session.isDragCustomMode
        ) {
          const coordinates = { long: val.longitude, lat: val.latitude };
          if (!this.$store.getters["preset/currentPreset"]) {
            mapControl.moveToLocation(this.map, coordinates, true);
          }
          mapControl.setZoomEnable(this.map, true);
        }
      }
    },

    objectTypes: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.setSelectedObjectStyles(newVal, this.selectedObjectTypes);
        }
      }
    },

    selectedObjectTypes: {
      deep: true,
      handler(newVal) {
        this.setSelectedObjectStyles(this.objectTypes, newVal);
      }
    },

    dragControlBarAction() {
      this.onButtonAction(this.dragControlBarAction);
    },

    async "$store.state.preset.selected"(val) {
      if (val) {
        const preset = this.$store.state.preset.presets.results.find(
          item => item.id === val
        );
        await this.loadPreset(preset);
      }
    }
  },

  methods: {
    loadFeatures(visibleFeatures, useProjection = false) {
      this.detectMonitorController.setFeaturesLayerVisible(false);
      const featureLayer = this.map
        .getLayers()
        .getArray()
        .find(item => item.get("name") === Constants.FEATURE_LAYER_NAME);

      featureLayer.setSource(
        new VectorSource({
          features: new GeoJSON(
            useProjection
              ? {
                  dataProjection: Constants.DEFAULT_DATA_PROJECTION,
                  featureProjection: Constants.DEFAULT_FEATURE_PROJECTION
                }
              : {}
          ).readFeatures(visibleFeatures)
        })
      );
      this.detectMonitorController.setFeaturesLayerVisible(true);
    },

    async _processDragCustomMode(preset) {
      //isCustomMode?
      this.isDragCustomMode = preset.extras.isDragCustomMode;

      if (this.isDragCustomMode) {
        this.$store.dispatch("session/updateDragCustom", true);
        this.$store.dispatch("session/finishDragCustom", true);
        this.setMapZoomPan(false);
        const centerAoi = preset.extras.customAreaAoi;
        this.detectMonitorController.applyDragArea(centerAoi, true);
      } else {
        this.detectMonitorController.clearCustomArea(false);
        this.$store.dispatch("session/updateDragCustom", false);
        this.$store.dispatch("session/finishDragCustom", false);
        this.setMapZoomPan(true);
      }
    },

    _processDetectedStatus(preset) {
      const { available } = preset.extras.filter;

      if (available.length > 0) {
        this.$store.dispatch("session/updateDetected", true);
      } else {
        this.$store.dispatch("session/updateDetected", false);
      }
    },

    _processRestoreFilters(preset) {
      const { available, selected } = preset.extras.filter;
      const features = preset.extras.features;
      this.$store
        .dispatch("session/loadObjectTypes", { useCache: true })
        .then(() => {
          //load features
          this.loadFeatures(features, true);
          //clear
          this.$store.dispatch("session/updateSelectedObjectTypes", []);
          this.$store.dispatch("session/updateAvailableObjectTypes", []);
          //switch
          this.$store.dispatch("session/updateSelectedObjectTypes", selected);
          this.$store.dispatch("session/updateAvailableObjectTypes", available);
        });
    },

    _processRestoreAoi(preset) {
      if (preset.extras.isDragCustomMode) {
        this.$map.set(Constants.MAP_CENTER_ROI_CUSTOM_PROP, preset.roi);
      } else {
        this.$map.set(Constants.MAP_CENTER_ROI_PROP, preset.roi);
      }
    },

    async loadPreset(preset) {
      this.detectMonitorController.setFeaturesLayerVisible(false);

      const center = [preset.longitude, preset.latitude];
      const zoomLevel = preset.extras.zoom_level;
      const activeMapId = preset.extras.activeMapId || Constants.DEFAULT_MAP_ID;

      //load map
      await this.$store.dispatch("session/updateSelectedYear", activeMapId);

      //load coordinates
      await this.$store.dispatch("session/updateSessionAddress", {
        location_name: preset.location_name,
        address: preset.address,
        longitude: preset.longitude,
        latitude: preset.latitude
      });

      //custom mode state
      await this._processDragCustomMode(preset);

      //detect state
      this._processDetectedStatus(preset);

      //move to location
      mapControl.animateTo(this.$map, fromLonLat(center), zoomLevel, () => {
        //restore aoi
        this._processRestoreAoi(preset);

        //load filters
        this._processRestoreFilters(preset);

        //done
        this.detectMonitorController.setFeaturesLayerVisible(true);
      });
    },

    initDragCustom(visible) {
      this.detectMonitorController.toggleDragArea(visible);
      this.dragControlBarAction = "transform";
      this.$store.dispatch("session/updateDetected", false);
      this.setMapZoomPan(true);
    },

    setMapZoomPan(enable) {
      mapControl.setZoomEnable(this.$map, enable);
      mapControl.setPanEnable(this.$map, enable);
    },

    onCloseAction: function() {
      this.$store.dispatch("session/updateDragCustom", false);
      this.$store.dispatch("session/finishDragCustom", false);
      this.setMapZoomPan(true);
      this.detectMonitorController.toggleDragArea(false);
      this.detectMonitorController.disableAllInteractions();
    },

    onSaveAction: function(roiObject, center) {
      this.$map.set(Constants.MAP_CENTER_ROI_CUSTOM_PROP, {
        bbox: roiObject.features[0].geometry.coordinates[0],
        origin: center
      });

      this.onRefocus(center);
      this.setMapZoomPan(false);

      this.$store.dispatch("session/finishDragCustom", true);
      this.$store.dispatch("session/updateDragCustom", true);
      this.detectMonitorController.disableAllInteractions();

      //reverse geolocation
      this.$store.dispatch("session/updateSessionAddressReverseCoding", {
        coordinate: center
      });
    },

    onRefocus(center) {
      mapControl.animateTo(
        this.$map,
        fromLonLat(center),
        process.env.VUE_APP_ZOOM_LEVEL
      );
    },

    onButtonAction: function(action) {
      if (this.$store.state.session.isFinishDragCustom) {
        return;
      }

      const {
        center,
        roiObject
      } = this.detectMonitorController.getDragCustomArea();

      switch (action) {
        case "close":
          this.onCloseAction();
          break;
        case "save":
          this.onSaveAction(roiObject, center);
          break;
        case "refocus":
          this.onRefocus(center);
          break;
        case "modify":
          this.detectMonitorController.enableDragCustomModify();
          this._last_action = action;
          break;
        case "transform":
          this.detectMonitorController.enableDragCustomTransform();
          this._last_action = action;
          break;
        case "refresh":
          this.detectMonitorController.resetDragCustomShape();

          if (this._last_action) {
            this.dragControlBarAction = this._last_action;
            if (this._last_action === "modify") {
              this.detectMonitorController.enableDragCustomModify();
            } else {
              this.detectMonitorController.enableDragCustomTransform();
            }
          } else {
            //reset transform action
            this.dragControlBarAction = "transform";
            this._last_action = "transform";
            this.detectMonitorController.enableDragCustomTransform();
          }
          break;
        default:
          this.detectMonitorController.enableDragCustomTransform();
          break;
      }
    },

    setSelectedObjectStyles: function(objectTypes, selectedObjectTypes) {
      if (
        objectTypes != null &&
        objectTypes.length > 0 &&
        selectedObjectTypes != null
      ) {
        this.filteredObjectsType = objectTypes[0].children.filter(type => {
          return selectedObjectTypes.includes(type.id);
        });
      } else {
        this.filteredObjectsType = null;
      }

      this.vector.setStyle(this.styleFunction);
    },

    styleFunction: function(feature) {
      let image = new Icon({
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: "noname"
      });

      if (
        this.filteredObjectsType != null &&
        this.filteredObjectsType.length >= 0 &&
        this.$store.state.session.session.detected
      ) {
        const object_type = feature.getProperties()["object_type"];

        let source = this.filteredObjectsType.find(obj => {
          return obj.name === object_type;
        });

        if (source != null) {
          const re = new RegExp("^(http|https)://", "i");
          const marker_src = re.test(source.marker)
            ? source.marker
            : process.env.VUE_APP_API_BASE_URL + source.marker;

          image = new Icon({
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: marker_src
          });
        }
      }

      return new Style({
        image
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~ol/ol.css";

.center-roi-wrapper {
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 240px;

  .center-roi {
    pointer-events: none;
    position: relative;
    border: 4px solid transparent;
    border-radius: 8px;
  }
  .active {
    border: 4px solid yellow;
  }
}

.map {
  height: 100%;
  background: #f8f4f0;
}
</style>
