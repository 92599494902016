import * as turf from "@turf/turf";

// just dummy file, will be deleted when we have api
import geojsonObject from "./DummyObject.json";
import { toLonLat } from "ol/proj";
import { uniq } from "lodash";

const getFeatures = ({ bbox }, selectedYear) => {
  const resultsGeoData = { ...geojsonObject[selectedYear], features: [] };

  for (let feature of geojsonObject[selectedYear].features) {
    const lonLat = toLonLat(feature.geometry.coordinates);

    if (bbox && bbox.length > 0) {
      const _bbox = turf.polygon([bbox]);
      //only show points inside bbox
      if (turf.booleanPointInPolygon(lonLat, _bbox)) {
        resultsGeoData.features.push(feature);
      }
    } else {
      //show all points
      resultsGeoData.features.push(feature);
    }
  }

  return {
    availableObjectTypes: uniq(
      resultsGeoData.features.map(feature => feature.properties.object_type_id)
    ),
    visibleFeatures: resultsGeoData
  };
};

export { getFeatures, geojsonObject };
